@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.cls-1 {
  fill: url(#Neues_Verlaufsfeld_2-5);
}

.cls-1,
.cls-2,
.cls-3,
.cls-4,
.cls-5,
.cls-6,
.cls-7,
.cls-8,
.cls-9,
.cls-10,
.cls-11,
.cls-12,
.cls-13,
.cls-14,
.cls-15,
.cls-16,
.cls-17,
.cls-18,
.cls-19,
.cls-20,
.cls-21,
.cls-22,
.cls-23,
.cls-24,
.cls-25,
.cls-26,
.cls-27,
.cls-28,
.cls-29,
.cls-30,
.cls-31,
.cls-32,
.cls-33,
.cls-34,
.cls-35,
.cls-36,
.cls-37,
.cls-38,
.cls-39,
.cls-40,
.cls-41,
.cls-42,
.cls-43,
.cls-44,
.cls-45,
.cls-46,
.cls-47,
.cls-48,
.cls-49,
.cls-50,
.cls-51,
.cls-52,
.cls-53,
.cls-54,
.cls-55,
.cls-56,
.cls-57,
.cls-58,
.cls-59,
.cls-60,
.cls-61,
.cls-62,
.cls-63,
.cls-64,
.cls-65,
.cls-66,
.cls-67,
.cls-68,
.cls-69,
.cls-70,
.cls-71,
.cls-72,
.cls-73,
.cls-74,
.cls-75,
.cls-76,
.cls-77,
.cls-78,
.cls-79,
.cls-80,
.cls-81,
.cls-82,
.cls-83,
.cls-84,
.cls-85,
.cls-86,
.cls-87,
.cls-88,
.cls-89,
.cls-90,
.cls-91,
.cls-92,
.cls-93,
.cls-94,
.cls-95,
.cls-96,
.cls-97,
.cls-98,
.cls-99,
.cls-100,
.cls-101,
.cls-102,
.cls-103,
.cls-104,
.cls-105,
.cls-106,
.cls-107,
.cls-108,
.cls-109,
.cls-110,
.cls-111,
.cls-112,
.cls-113,
.cls-114,
.cls-115,
.cls-116,
.cls-117,
.cls-118,
.cls-119,
.cls-120,
.cls-121,
.cls-122,
.cls-123,
.cls-124,
.cls-125,
.cls-126,
.cls-127,
.cls-128,
.cls-129,
.cls-130,
.cls-131,
.cls-132,
.cls-133,
.cls-134,
.cls-135,
.cls-136,
.cls-137,
.cls-138,
.cls-139,
.cls-140,
.cls-141,
.cls-142,
.cls-143,
.cls-144,
.cls-145,
.cls-146,
.cls-147,
.cls-148,
.cls-149,
.cls-150,
.cls-151,
.cls-152,
.cls-153,
.cls-154,
.cls-155,
.cls-156,
.cls-157,
.cls-158,
.cls-159,
.cls-160,
.cls-161,
.cls-162,
.cls-163,
.cls-164,
.cls-165,
.cls-166,
.cls-167,
.cls-168,
.cls-169,
.cls-170,
.cls-171,
.cls-172,
.cls-173,
.cls-174,
.cls-175,
.cls-176,
.cls-177,
.cls-178,
.cls-179,
.cls-180,
.cls-181,
.cls-182,
.cls-183,
.cls-184,
.cls-185,
.cls-186,
.cls-187,
.cls-188,
.cls-189,
.cls-190,
.cls-191,
.cls-192,
.cls-193,
.cls-194,
.cls-195,
.cls-196,
.cls-197,
.cls-198,
.cls-199,
.cls-200,
.cls-201,
.cls-202,
.cls-203,
.cls-204,
.cls-205,
.cls-206,
.cls-207,
.cls-208,
.cls-209,
.cls-210,
.cls-211,
.cls-212,
.cls-213,
.cls-214,
.cls-215,
.cls-216,
.cls-217,
.cls-218,
.cls-219,
.cls-220,
.cls-221,
.cls-222,
.cls-223,
.cls-224,
.cls-225,
.cls-226,
.cls-227,
.cls-228,
.cls-229,
.cls-230,
.cls-231,
.cls-232,
.cls-233,
.cls-234,
.cls-235,
.cls-236,
.cls-237,
.cls-238,
.cls-239,
.cls-240,
.cls-241,
.cls-242,
.cls-243,
.cls-244,
.cls-245,
.cls-246,
.cls-247,
.cls-248,
.cls-249,
.cls-250,
.cls-251,
.cls-252,
.cls-253,
.cls-254,
.cls-255,
.cls-256,
.cls-257,
.cls-258,
.cls-259,
.cls-260,
.cls-261,
.cls-262,
.cls-263,
.cls-264,
.cls-265,
.cls-266,
.cls-267,
.cls-268,
.cls-269,
.cls-270,
.cls-271,
.cls-272,
.cls-273,
.cls-274,
.cls-275,
.cls-276,
.cls-277,
.cls-278,
.cls-279,
.cls-280,
.cls-281,
.cls-282,
.cls-283,
.cls-284,
.cls-285,
.cls-286,
.cls-287,
.cls-288 {
  stroke-width: 0px;
}

.cls-2 {
  fill: url(#Neues_Verlaufsfeld_2-9);
}

.cls-3 {
  fill: url(#Neues_Verlaufsfeld_2-6);
}

.cls-4 {
  fill: url(#Neues_Verlaufsfeld_2-4);
}

.cls-5 {
  fill: url(#Neues_Verlaufsfeld_2-3);
}

.cls-6 {
  fill: url(#Neues_Verlaufsfeld_2-2);
}

.cls-7 {
  fill: url(#Neues_Verlaufsfeld_2-8);
}

.cls-8 {
  fill: url(#Neues_Verlaufsfeld_2-7);
}

.cls-289 {
  fill: url(#Neues_Verlaufsfeld_2-38);
}

.cls-290 {
  fill: url(#Neues_Verlaufsfeld_2-99);
}

.cls-291 {
  fill: url(#Neues_Verlaufsfeld_2-21);
}

.cls-9 {
  fill: url(#Neues_Verlaufsfeld_2-205);
}

.cls-10 {
  fill: url(#Neues_Verlaufsfeld_2-203);
}

.cls-11 {
  fill: url(#Neues_Verlaufsfeld_2-202);
}

.cls-12 {
  fill: url(#Neues_Verlaufsfeld_2-200);
}

.cls-13 {
  fill: url(#Neues_Verlaufsfeld_2-201);
}

.cls-14 {
  fill: url(#Neues_Verlaufsfeld_2-204);
}

.cls-15 {
  fill: url(#Neues_Verlaufsfeld_2-208);
}

.cls-16 {
  fill: url(#Neues_Verlaufsfeld_2-206);
}

.cls-17 {
  fill: url(#Neues_Verlaufsfeld_2-209);
}

.cls-18 {
  fill: url(#Neues_Verlaufsfeld_2-207);
}

.cls-19 {
  fill: url(#Neues_Verlaufsfeld_2-244);
}

.cls-20 {
  fill: url(#Neues_Verlaufsfeld_2-249);
}

.cls-21 {
  fill: url(#Neues_Verlaufsfeld_2-246);
}

.cls-22 {
  fill: url(#Neues_Verlaufsfeld_2-243);
}

.cls-23 {
  fill: url(#Neues_Verlaufsfeld_2-214);
}

.cls-24 {
  fill: url(#Neues_Verlaufsfeld_2-212);
}

.cls-25 {
  fill: url(#Neues_Verlaufsfeld_2-213);
}

.cls-26 {
  fill: url(#Neues_Verlaufsfeld_2-277);
}

.cls-27 {
  fill: url(#Neues_Verlaufsfeld_2-278);
}

.cls-28 {
  fill: url(#Neues_Verlaufsfeld_2-275);
}

.cls-29 {
  fill: url(#Neues_Verlaufsfeld_2-267);
}

.cls-30 {
  fill: url(#Neues_Verlaufsfeld_2-264);
}

.cls-31 {
  fill: url(#Neues_Verlaufsfeld_2-265);
}

.cls-32 {
  fill: url(#Neues_Verlaufsfeld_2-261);
}

.cls-33 {
  fill: url(#Neues_Verlaufsfeld_2-260);
}

.cls-34 {
  fill: url(#Neues_Verlaufsfeld_2-262);
}

.cls-35 {
  fill: url(#Neues_Verlaufsfeld_2-248);
}

.cls-36 {
  fill: url(#Neues_Verlaufsfeld_2-247);
}

.cls-37 {
  fill: url(#Neues_Verlaufsfeld_2-279);
}

.cls-38 {
  fill: url(#Neues_Verlaufsfeld_2-276);
}

.cls-39 {
  fill: url(#Neues_Verlaufsfeld_2-273);
}

.cls-40 {
  fill: url(#Neues_Verlaufsfeld_2-274);
}

.cls-41 {
  fill: url(#Neues_Verlaufsfeld_2-211);
}

.cls-42 {
  fill: url(#Neues_Verlaufsfeld_2-268);
}

.cls-43 {
  fill: url(#Neues_Verlaufsfeld_2-269);
}

.cls-44 {
  fill: url(#Neues_Verlaufsfeld_2-263);
}

.cls-45 {
  fill: url(#Neues_Verlaufsfeld_2-266);
}

.cls-46 {
  fill: url(#Neues_Verlaufsfeld_2-218);
}

.cls-47 {
  fill: url(#Neues_Verlaufsfeld_2-216);
}

.cls-48 {
  fill: url(#Neues_Verlaufsfeld_2-215);
}

.cls-49 {
  fill: url(#Neues_Verlaufsfeld_2-219);
}

.cls-50 {
  fill: url(#Neues_Verlaufsfeld_2-210);
}

.cls-51 {
  fill: url(#Neues_Verlaufsfeld_2-217);
}

.cls-52 {
  fill: url(#Neues_Verlaufsfeld_2-281);
}

.cls-53 {
  fill: url(#Neues_Verlaufsfeld_2-287);
}

.cls-54 {
  fill: url(#Neues_Verlaufsfeld_2-286);
}

.cls-55 {
  fill: url(#Neues_Verlaufsfeld_2-280);
}

.cls-56 {
  fill: url(#Neues_Verlaufsfeld_2-288);
}

.cls-57 {
  fill: url(#Neues_Verlaufsfeld_2-283);
}

.cls-58 {
  fill: url(#Neues_Verlaufsfeld_2-282);
}

.cls-59 {
  fill: url(#Neues_Verlaufsfeld_2-292);
}

.cls-60 {
  fill: url(#Neues_Verlaufsfeld_2-290);
}

.cls-61 {
  fill: url(#Neues_Verlaufsfeld_2-291);
}

.cls-62 {
  fill: url(#Neues_Verlaufsfeld_2-289);
}

.cls-63 {
  fill: url(#Neues_Verlaufsfeld_2-250);
}

.cls-64 {
  fill: url(#Neues_Verlaufsfeld_2-251);
}

.cls-65 {
  fill: url(#Neues_Verlaufsfeld_2-252);
}

.cls-66 {
  fill: url(#Neues_Verlaufsfeld_2-285);
}

.cls-67 {
  fill: url(#Neues_Verlaufsfeld_2-284);
}

.cls-68 {
  fill: url(#Neues_Verlaufsfeld_2-270);
}

.cls-69 {
  fill: url(#Neues_Verlaufsfeld_2-271);
}

.cls-70 {
  fill: url(#Neues_Verlaufsfeld_2-272);
}

.cls-71 {
  fill: url(#Neues_Verlaufsfeld_2-245);
}

.cls-72 {
  fill: url(#Neues_Verlaufsfeld_2-257);
}

.cls-73 {
  fill: url(#Neues_Verlaufsfeld_2-258);
}

.cls-74 {
  fill: url(#Neues_Verlaufsfeld_2-254);
}

.cls-75 {
  fill: url(#Neues_Verlaufsfeld_2-253);
}

.cls-76 {
  fill: url(#Neues_Verlaufsfeld_2-259);
}

.cls-77 {
  fill: url(#Neues_Verlaufsfeld_2-240);
}

.cls-78 {
  fill: url(#Neues_Verlaufsfeld_2-241);
}

.cls-79 {
  fill: url(#Neues_Verlaufsfeld_2-242);
}

.cls-80 {
  fill: url(#Neues_Verlaufsfeld_2-256);
}

.cls-81 {
  fill: url(#Neues_Verlaufsfeld_2-255);
}

.cls-82 {
  fill: url(#Neues_Verlaufsfeld_2-150);
}

.cls-83 {
  fill: url(#Neues_Verlaufsfeld_2-152);
}

.cls-84 {
  fill: url(#Neues_Verlaufsfeld_2-164);
}

.cls-85 {
  fill: url(#Neues_Verlaufsfeld_2-140);
}

.cls-86 {
  fill: url(#Neues_Verlaufsfeld_2-141);
}

.cls-87 {
  fill: url(#Neues_Verlaufsfeld_2-151);
}

.cls-88 {
  fill: url(#Neues_Verlaufsfeld_2-155);
}

.cls-89 {
  fill: url(#Neues_Verlaufsfeld_2-157);
}

.cls-90 {
  fill: url(#Neues_Verlaufsfeld_2-158);
}

.cls-91 {
  fill: url(#Neues_Verlaufsfeld_2-159);
}

.cls-92 {
  fill: url(#Neues_Verlaufsfeld_2-154);
}

.cls-93 {
  fill: url(#Neues_Verlaufsfeld_2-153);
}

.cls-94 {
  fill: url(#Neues_Verlaufsfeld_2-156);
}

.cls-95 {
  fill: url(#Neues_Verlaufsfeld_2-144);
}

.cls-96 {
  fill: url(#Neues_Verlaufsfeld_2-143);
}

.cls-97 {
  fill: url(#Neues_Verlaufsfeld_2-142);
}

.cls-98 {
  fill: url(#Neues_Verlaufsfeld_2-145);
}

.cls-99 {
  fill: url(#Neues_Verlaufsfeld_2-166);
}

.cls-100 {
  fill: url(#Neues_Verlaufsfeld_2-167);
}

.cls-101 {
  fill: url(#Neues_Verlaufsfeld_2-169);
}

.cls-102 {
  fill: url(#Neues_Verlaufsfeld_2-165);
}

.cls-103 {
  fill: url(#Neues_Verlaufsfeld_2-163);
}

.cls-104 {
  fill: url(#Neues_Verlaufsfeld_2-168);
}

.cls-105 {
  fill: url(#Neues_Verlaufsfeld_2-147);
}

.cls-106 {
  fill: url(#Neues_Verlaufsfeld_2-146);
}

.cls-107 {
  fill: url(#Neues_Verlaufsfeld_2-148);
}

.cls-108 {
  fill: url(#Neues_Verlaufsfeld_2-149);
}

.cls-109 {
  fill: url(#Neues_Verlaufsfeld_2-199);
}

.cls-110 {
  fill: url(#Neues_Verlaufsfeld_2-198);
}

.cls-111 {
  fill: url(#Neues_Verlaufsfeld_2-193);
}

.cls-112 {
  fill: url(#Neues_Verlaufsfeld_2-194);
}

.cls-113 {
  fill: url(#Neues_Verlaufsfeld_2-195);
}

.cls-114 {
  fill: url(#Neues_Verlaufsfeld_2-197);
}

.cls-115 {
  fill: url(#Neues_Verlaufsfeld_2-190);
}

.cls-116 {
  fill: url(#Neues_Verlaufsfeld_2-196);
}

.cls-117 {
  fill: url(#Neues_Verlaufsfeld_2-191);
}

.cls-118 {
  fill: url(#Neues_Verlaufsfeld_2-184);
}

.cls-119 {
  fill: url(#Neues_Verlaufsfeld_2-185);
}

.cls-120 {
  fill: url(#Neues_Verlaufsfeld_2-182);
}

.cls-121 {
  fill: url(#Neues_Verlaufsfeld_2-183);
}

.cls-122 {
  fill: url(#Neues_Verlaufsfeld_2-171);
}

.cls-123 {
  fill: url(#Neues_Verlaufsfeld_2-188);
}

.cls-124 {
  fill: url(#Neues_Verlaufsfeld_2-189);
}

.cls-125 {
  fill: url(#Neues_Verlaufsfeld_2-186);
}

.cls-126 {
  fill: url(#Neues_Verlaufsfeld_2-187);
}

.cls-127 {
  fill: url(#Neues_Verlaufsfeld_2-180);
}

.cls-128 {
  fill: url(#Neues_Verlaufsfeld_2-181);
}

.cls-129 {
  fill: url(#Neues_Verlaufsfeld_2-170);
}

.cls-130 {
  fill: url(#Neues_Verlaufsfeld_2-172);
}

.cls-131 {
  fill: url(#Neues_Verlaufsfeld_2-176);
}

.cls-132 {
  fill: url(#Neues_Verlaufsfeld_2-175);
}

.cls-133 {
  fill: url(#Neues_Verlaufsfeld_2-178);
}

.cls-134 {
  fill: url(#Neues_Verlaufsfeld_2-177);
}

.cls-135 {
  fill: url(#Neues_Verlaufsfeld_2-161);
}

.cls-136 {
  fill: url(#Neues_Verlaufsfeld_2-162);
}

.cls-137 {
  fill: url(#Neues_Verlaufsfeld_2-160);
}

.cls-138 {
  fill: url(#Neues_Verlaufsfeld_2-173);
}

.cls-139 {
  fill: url(#Neues_Verlaufsfeld_2-174);
}

.cls-140 {
  fill: url(#Neues_Verlaufsfeld_2-179);
}

.cls-141 {
  fill: url(#Neues_Verlaufsfeld_2-133);
}

.cls-142 {
  fill: url(#Neues_Verlaufsfeld_2-135);
}

.cls-143 {
  fill: url(#Neues_Verlaufsfeld_2-134);
}

.cls-144 {
  fill: url(#Neues_Verlaufsfeld_2-130);
}

.cls-145 {
  fill: url(#Neues_Verlaufsfeld_2-132);
}

.cls-146 {
  fill: url(#Neues_Verlaufsfeld_2-131);
}

.cls-147 {
  fill: url(#Neues_Verlaufsfeld_2-139);
}

.cls-148 {
  fill: url(#Neues_Verlaufsfeld_2-136);
}

.cls-149 {
  fill: url(#Neues_Verlaufsfeld_2-137);
}

.cls-150 {
  fill: url(#Neues_Verlaufsfeld_2-138);
}

.cls-151 {
  fill: url(#Neues_Verlaufsfeld_2-123);
}

.cls-152 {
  fill: url(#Neues_Verlaufsfeld_2-121);
}

.cls-153 {
  fill: url(#Neues_Verlaufsfeld_2-120);
}

.cls-154 {
  fill: url(#Neues_Verlaufsfeld_2-112);
}

.cls-155 {
  fill: url(#Neues_Verlaufsfeld_2-113);
}

.cls-156 {
  fill: url(#Neues_Verlaufsfeld_2-100);
}

.cls-157 {
  fill: url(#Neues_Verlaufsfeld_2-101);
}

.cls-158 {
  fill: url(#Neues_Verlaufsfeld_2-106);
}

.cls-159 {
  fill: url(#Neues_Verlaufsfeld_2-107);
}

.cls-160 {
  fill: url(#Neues_Verlaufsfeld_2-104);
}

.cls-161 {
  fill: url(#Neues_Verlaufsfeld_2-108);
}

.cls-162 {
  fill: url(#Neues_Verlaufsfeld_2-105);
}

.cls-163 {
  fill: url(#Neues_Verlaufsfeld_2-109);
}

.cls-164 {
  fill: url(#Neues_Verlaufsfeld_2-103);
}

.cls-165 {
  fill: url(#Neues_Verlaufsfeld_2-129);
}

.cls-166 {
  fill: url(#Neues_Verlaufsfeld_2-128);
}

.cls-167 {
  fill: url(#Neues_Verlaufsfeld_2-102);
}

.cls-168 {
  fill: url(#Neues_Verlaufsfeld_2-122);
}

.cls-169 {
  fill: url(#Neues_Verlaufsfeld_2-124);
}

.cls-170 {
  fill: url(#Neues_Verlaufsfeld_2-127);
}

.cls-171 {
  fill: url(#Neues_Verlaufsfeld_2-125);
}

.cls-172 {
  fill: url(#Neues_Verlaufsfeld_2-126);
}

.cls-173 {
  fill: url(#Neues_Verlaufsfeld_2-111);
}

.cls-174 {
  fill: url(#Neues_Verlaufsfeld_2-110);
}

.cls-175 {
  fill: url(#Neues_Verlaufsfeld_2-116);
}

.cls-176 {
  fill: url(#Neues_Verlaufsfeld_2-117);
}

.cls-177 {
  fill: url(#Neues_Verlaufsfeld_2-115);
}

.cls-178 {
  fill: url(#Neues_Verlaufsfeld_2-114);
}

.cls-179 {
  fill: url(#Neues_Verlaufsfeld_2-119);
}

.cls-180 {
  fill: url(#Neues_Verlaufsfeld_2-118);
}

.cls-181 {
  fill: url(#Neues_Verlaufsfeld_2-228);
}

.cls-182 {
  fill: url(#Neues_Verlaufsfeld_2-221);
}

.cls-183 {
  fill: url(#Neues_Verlaufsfeld_2-227);
}

.cls-184 {
  fill: url(#Neues_Verlaufsfeld_2-222);
}

.cls-185 {
  fill: url(#Neues_Verlaufsfeld_2-226);
}

.cls-186 {
  fill: url(#Neues_Verlaufsfeld_2-229);
}

.cls-187 {
  fill: url(#Neues_Verlaufsfeld_2-223);
}

.cls-188 {
  fill: url(#Neues_Verlaufsfeld_2-220);
}

.cls-189 {
  fill: url(#Neues_Verlaufsfeld_2-225);
}

.cls-190 {
  fill: url(#Neues_Verlaufsfeld_2-224);
}

.cls-191 {
  fill: url(#Neues_Verlaufsfeld_2-238);
}

.cls-192 {
  fill: url(#Neues_Verlaufsfeld_2-231);
}

.cls-193 {
  fill: url(#Neues_Verlaufsfeld_2-237);
}

.cls-194 {
  fill: url(#Neues_Verlaufsfeld_2-236);
}

.cls-195 {
  fill: url(#Neues_Verlaufsfeld_2-239);
}

.cls-196 {
  fill: url(#Neues_Verlaufsfeld_2-230);
}

.cls-197 {
  fill: url(#Neues_Verlaufsfeld_2-233);
}

.cls-198 {
  fill: url(#Neues_Verlaufsfeld_2-234);
}

.cls-199 {
  fill: url(#Neues_Verlaufsfeld_2-232);
}

.cls-200 {
  fill: url(#Neues_Verlaufsfeld_2-235);
}

.cls-292 {
  clip-path: url(#clippath);
}

.cls-293,
.cls-288 {
  fill: url(#Neues_Verlaufsfeld_2);
}

.cls-201 {
  fill: url(#Neues_Verlaufsfeld_2-83);
}

.cls-202 {
  fill: url(#Neues_Verlaufsfeld_2-86);
}

.cls-203 {
  fill: url(#Neues_Verlaufsfeld_2-89);
}

.cls-204 {
  fill: url(#Neues_Verlaufsfeld_2-88);
}

.cls-205 {
  fill: url(#Neues_Verlaufsfeld_2-87);
}

.cls-206 {
  fill: url(#Neues_Verlaufsfeld_2-80);
}

.cls-207 {
  fill: url(#Neues_Verlaufsfeld_2-82);
}

.cls-208 {
  fill: url(#Neues_Verlaufsfeld_2-81);
}

.cls-209 {
  fill: url(#Neues_Verlaufsfeld_2-84);
}

.cls-210 {
  fill: url(#Neues_Verlaufsfeld_2-85);
}

.cls-211 {
  fill: url(#Neues_Verlaufsfeld_2-22);
}

.cls-212 {
  fill: url(#Neues_Verlaufsfeld_2-20);
}

.cls-213 {
  fill: url(#Neues_Verlaufsfeld_2-26);
}

.cls-214 {
  fill: url(#Neues_Verlaufsfeld_2-23);
}

.cls-215 {
  fill: url(#Neues_Verlaufsfeld_2-28);
}

.cls-216 {
  fill: url(#Neues_Verlaufsfeld_2-29);
}

.cls-217 {
  fill: url(#Neues_Verlaufsfeld_2-25);
}

.cls-218 {
  fill: url(#Neues_Verlaufsfeld_2-24);
}

.cls-219 {
  fill: url(#Neues_Verlaufsfeld_2-27);
}

.cls-220 {
  fill: url(#Neues_Verlaufsfeld_2-12);
}

.cls-221 {
  fill: url(#Neues_Verlaufsfeld_2-13);
}

.cls-222 {
  fill: url(#Neues_Verlaufsfeld_2-18);
}

.cls-223 {
  fill: url(#Neues_Verlaufsfeld_2-19);
}

.cls-224 {
  fill: url(#Neues_Verlaufsfeld_2-15);
}

.cls-225 {
  fill: url(#Neues_Verlaufsfeld_2-14);
}

.cls-226 {
  fill: url(#Neues_Verlaufsfeld_2-10);
}

.cls-227 {
  fill: url(#Neues_Verlaufsfeld_2-11);
}

.cls-228 {
  fill: url(#Neues_Verlaufsfeld_2-16);
}

.cls-229 {
  fill: url(#Neues_Verlaufsfeld_2-17);
}

.cls-230 {
  fill: url(#Neues_Verlaufsfeld_2-60);
}

.cls-231 {
  fill: url(#Neues_Verlaufsfeld_2-61);
}

.cls-232 {
  fill: url(#Neues_Verlaufsfeld_2-62);
}

.cls-233 {
  fill: url(#Neues_Verlaufsfeld_2-63);
}

.cls-234 {
  fill: url(#Neues_Verlaufsfeld_2-64);
}

.cls-235 {
  fill: url(#Neues_Verlaufsfeld_2-65);
}

.cls-236 {
  fill: url(#Neues_Verlaufsfeld_2-66);
}

.cls-237 {
  fill: url(#Neues_Verlaufsfeld_2-96);
}

.cls-238 {
  fill: url(#Neues_Verlaufsfeld_2-97);
}

.cls-239 {
  fill: url(#Neues_Verlaufsfeld_2-98);
}

.cls-240 {
  fill: url(#Neues_Verlaufsfeld_2-92);
}

.cls-241 {
  fill: url(#Neues_Verlaufsfeld_2-94);
}

.cls-242 {
  fill: url(#Neues_Verlaufsfeld_2-95);
}

.cls-243 {
  fill: url(#Neues_Verlaufsfeld_2-93);
}

.cls-244 {
  fill: url(#Neues_Verlaufsfeld_2-90);
}

.cls-245 {
  fill: url(#Neues_Verlaufsfeld_2-91);
}

.cls-246 {
  fill: url(#Neues_Verlaufsfeld_2-47);
}

.cls-247 {
  fill: url(#Neues_Verlaufsfeld_2-48);
}

.cls-248 {
  fill: url(#Neues_Verlaufsfeld_2-49);
}

.cls-249 {
  fill: url(#Neues_Verlaufsfeld_2-56);
}

.cls-250 {
  fill: url(#Neues_Verlaufsfeld_2-57);
}

.cls-251 {
  fill: url(#Neues_Verlaufsfeld_2-58);
}

.cls-252 {
  fill: url(#Neues_Verlaufsfeld_2-59);
}

.cls-253 {
  fill: url(#Neues_Verlaufsfeld_2-50);
}

.cls-254 {
  fill: url(#Neues_Verlaufsfeld_2-53);
}

.cls-255 {
  fill: url(#Neues_Verlaufsfeld_2-51);
}

.cls-256 {
  fill: url(#Neues_Verlaufsfeld_2-55);
}

.cls-257 {
  fill: url(#Neues_Verlaufsfeld_2-54);
}

.cls-258 {
  fill: url(#Neues_Verlaufsfeld_2-52);
}

.cls-259 {
  fill: url(#Neues_Verlaufsfeld_2-69);
}

.cls-260 {
  fill: url(#Neues_Verlaufsfeld_2-31);
}

.cls-261 {
  fill: url(#Neues_Verlaufsfeld_2-32);
}

.cls-262 {
  fill: url(#Neues_Verlaufsfeld_2-68);
}

.cls-263 {
  fill: url(#Neues_Verlaufsfeld_2-67);
}

.cls-264 {
  fill: url(#Neues_Verlaufsfeld_2-33);
}

.cls-265 {
  fill: url(#Neues_Verlaufsfeld_2-30);
}

.cls-266 {
  fill: url(#Neues_Verlaufsfeld_2-35);
}

.cls-267 {
  fill: url(#Neues_Verlaufsfeld_2-36);
}

.cls-268 {
  fill: url(#Neues_Verlaufsfeld_2-37);
}

.cls-269 {
  fill: url(#Neues_Verlaufsfeld_2-34);
}

.cls-270 {
  fill: url(#Neues_Verlaufsfeld_2-39);
}

.cls-271 {
  fill: url(#Neues_Verlaufsfeld_2-44);
}

.cls-272 {
  fill: url(#Neues_Verlaufsfeld_2-78);
}

.cls-273 {
  fill: url(#Neues_Verlaufsfeld_2-42);
}

.cls-274 {
  fill: url(#Neues_Verlaufsfeld_2-46);
}

.cls-275 {
  fill: url(#Neues_Verlaufsfeld_2-43);
}

.cls-276 {
  fill: url(#Neues_Verlaufsfeld_2-40);
}

.cls-277 {
  fill: url(#Neues_Verlaufsfeld_2-45);
}

.cls-278 {
  fill: url(#Neues_Verlaufsfeld_2-74);
}

.cls-279 {
  fill: url(#Neues_Verlaufsfeld_2-75);
}

.cls-280 {
  fill: url(#Neues_Verlaufsfeld_2-71);
}

.cls-281 {
  fill: url(#Neues_Verlaufsfeld_2-73);
}

.cls-282 {
  fill: url(#Neues_Verlaufsfeld_2-70);
}

.cls-283 {
  fill: url(#Neues_Verlaufsfeld_2-72);
}

.cls-284 {
  fill: url(#Neues_Verlaufsfeld_2-41);
}

.cls-285 {
  fill: url(#Neues_Verlaufsfeld_2-76);
}

.cls-286 {
  fill: url(#Neues_Verlaufsfeld_2-79);
}

.cls-287 {
  fill: url(#Neues_Verlaufsfeld_2-77);
}

.cls-294 {
  clip-path: url(#clippath-1);
}

.cls-295 {
  clip-path: url(#clippath-4);
}

.cls-296 {
  clip-path: url(#clippath-3);
}

.cls-297 {
  clip-path: url(#clippath-2);
}

.cls-298 {
  fill: url(#Neues_Verlaufsfeld_2-192);
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-primary);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.content-page {
  color: #444;
}

.content-page h1 {
  font-size: 3rem;
  font-weight: 700;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.content-page h2 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.content-page h3 {
  font-size: 1rem;
  font-weight: 700;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.content-page ul {
  padding: 2rem;
  line-height: 1.5rem;
  list-style: disc;
}
.content-page p {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 1rem;
}

.terms-dialog h2 {
  font-size: 1rem;
  font-weight: 700;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.terms-dialog h3 {
  font-size: 0.8rem;
  font-weight: 700;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.terms-dialog ul {
  padding: 0.5rem 2rem;
  font-size: 0.8rem;
  line-height: 1rem;
  list-style: disc;
}
.terms-dialog p {
  font-size: 0.8rem;
  line-height: 1rem;
  margin-bottom: 1rem;
}
