.cls-1 {
    fill: url(#Neues_Verlaufsfeld_2-5);
  }

  .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
    stroke-width: 0px;
  }

  .cls-2 {
    fill: url(#Neues_Verlaufsfeld_2-9);
  }

  .cls-3 {
    fill: url(#Neues_Verlaufsfeld_2-6);
  }

  .cls-4 {
    fill: url(#Neues_Verlaufsfeld_2-4);
  }

  .cls-5 {
    fill: url(#Neues_Verlaufsfeld_2-3);
  }

  .cls-6 {
    fill: url(#Neues_Verlaufsfeld_2-2);
  }

  .cls-7 {
    fill: url(#Neues_Verlaufsfeld_2-8);
  }

  .cls-8 {
    fill: url(#Neues_Verlaufsfeld_2-7);
  }

  .cls-9 {
    fill: url(#Neues_Verlaufsfeld_2);
  }

  .arcbot-logo {
        width: 140px;
        position: relative;
        top: -5px;
        padding: 4px;
  }